.login {
  padding: 20px;
  text-align: center;
  grid-area: content;

  h2 {
    font-size: 16px;
    color: #48544c;
    font-weight: bold;
    font-family: 'Arvo', serif;
  }
}

.login-form-container {
  width: 100%;
}

.login-errors-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.enter-password-text {
  color: #5b7065;
  margin-bottom: 10px;
}

.login-form {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 18px;
    color: #5b7065;
    font-weight: 400;
    box-shadow: none;
    background-color: #fff;
    -webkit-appearance: none;
  }
  input[type='submit'] {
    //background: #ec5990;
    background-color: #5b7065;
    border: 1px solid #5b7065;
    border-radius: 30px;
    box-shadow: 0 5px 25px -8px rgba(31, 26, 55, 0.3);
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.02em;
    padding: 20px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    white-space: nowrap;
    margin: 10px 0;
    max-width: 200px;
  }
}
.login-with-form {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
}

.login-with-scanner {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.login-form-item {
  margin: 0.5em 0;
  width: 100%;
  max-width: 500px;
}

.login-form-toggle-button {
  margin: 1em 0;
  max-width: 500px;
  width: 100%;
  border: none;
  font-weight: 700;
}

.horizontal-line {
  width: 40%;
  height: 1px;
  background-color: #ddd;
}

.divider-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0;
  width: 100%;
}
