.scanner {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /*border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;*/
  font-size: 30px;
  text-align: center;
  max-width: 500px;
  margin: auto;
}

.scanner h2 {
  font-size: 16px;
  color: #48544c;
  font-weight: bold;
  font-family: 'Arvo', serif;
  margin-bottom: 20px;
}
