.old-entries-container {
  grid-area: content;
  height: 100%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  button {
    margin: 10px 0 10px 0;
    max-width: 300px;
    width: 100%;
  }
  h1 {
    color: #48544c;
    font-size: 18px;
    color: #48544c;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: 'Arvo', serif;
  }

  h2 {
    color: #48544c;
    font-family: 'Arvo', serif;
    font-size: 16px;
    font-weight: bold;
  }
}

.filter-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.main-title {
  margin-bottom: 20px;
}

.old-entries-list {
  margin: 20px;
}

.button-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__month-container {
  float: left;
  width: 100%;
}

.date-picker-old-events {
  width: 100%;
  text-align: center;
  margin: 10px;
  input {
    box-sizing: border-box;
    width: 100%;
    border-radius: 30px;
    border: 1px solid #a4a7b5;
    padding: 10px 15px;
    font-size: 1rem;
    color: #48544c;
    font-weight: 400;
    box-shadow: none;
    max-width: 300px;
    height: 2.5rem;
    margin-top: 10px;
  }
  label {
    font-size: 0.875rem;
  }
}

.old-events-picklist {
  margin: 10px;
  span {
    z-index: 0;
  }
  span::after {
    color: #48544c;
  }
  input {
    color: #48544c;
  }
  input:focus {
    border: 2px solid #5b7065;
    box-shadow: 0 0 2px #5b7065;
  }
}

.filter-wrapper {
  label {
    text-transform: none;
  }
}
