.camera {
  grid-area: camera;
}
.camera {
  width: 100%;
}

// Outer wrapper
.qr-image-wrapper section {
  border-radius: 30px;
  overflow: hidden;
}

// Inner wrapper
.qr-image-wrapper section div {
  box-shadow: unset !important;
  /*box-shadow: rgba(160, 204, 175, 0.5) 0px 0px 0px 5px inset !important;*/
  border: 10px solid rgba(60, 95, 71, 0.5) !important;
  border-radius: 30px;
}

.qr-image-wrapper-error section div {
  border: 10px solid rgba(218, 40, 63, 0.5) !important;
}
