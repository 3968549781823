.culture-operator {
  font-size: 30px;
  text-align: center;
  grid-area: content;
  justify-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);

  input {
    font-size: 35px;
    border: 1px solid #5b7065;
    margin: 10px;
    border-radius: 4px;
    width: 300px;
    height: 50px;
    background-color: #ffffff;
    box-shadow: none;
    -webkit-appearance: none;
  }

  label {
    font-size: 12px;
  }

  p {
    font-size: 16px;
    margin: 20px;
  }

  h1 {
    font-size: 18px;
    color: #48544c;
    font-weight: bold;
    font-family: 'Arvo', serif;
    margin: 20px;
  }

  h2 {
    font-size: 16px;
    color: #48544c;
    font-weight: bold;
    font-family: 'Arvo', serif;
  }
}

.card-scanner {
  margin-top: 30px;
}

.manual-card-number {
  margin: 30px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.operator-buttons-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  button {
    max-width: 300px;
    width: 100%;
    margin: 10px;
  }
}

.horizontal-line-wide {
  width: 100%;
  height: 1px;
  background-color: #ddd;
}

.culture-operator-form {
  max-width: 500px;
  width: 100%;
}
