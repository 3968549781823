.entry {
  grid-area: content;
  padding: 20px;
  font-size: 30px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-flow: column;
  align-items: center;
}

.entry h3 {
  font-size: 20px;
  font-weight: bold;
}

.entry h1 {
  margin: 20px;
  font-family: 'Arvo', serif;
}
