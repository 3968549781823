.success-message {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  background-color: #4caf50;
  padding: 10px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  max-width: 500px;
}

.message-body {
  color: white;
  font-weight: 600;
  font-size: 12px;
  padding: 0 6px;
  width: 100%;
}
