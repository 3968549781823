@import './reset.scss';

.App {
  text-align: center;
}

.grid-container {
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    '. language .'
    '. logo .'
    '. content .'
    '. footer .';
  height: 100vh;
  max-width: 500px;
  width: 100%;
  background-image: url('./assets/iso_elementti_png.png');
  justify-content: center;
  background-size: 70%;
  background-position-y: -15%;
  background-position-x: 160%;
  background-repeat: no-repeat;
  background-color: #fff;
}

button {
  background-color: #5b7065;
  border: 2px solid #5b7065;
  border-radius: 30px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  white-space: nowrap;
  max-width: 300px;
}

.language {
  grid-area: language;
}