.entry-form h1 {
  margin: 5px !important;
}

.entry-form {
  width: 100%;
  max-width: 500px;

  input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 80%);
    padding: 10px 15px;
    font-size: 16px;
    color: #48544c;
    font-weight: 400;
    box-shadow: none;
    background-color: #fff;
  }

  input[type='number'] {
    color: #48544c;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid hsl(0, 0%, 80%);
    box-shadow: none;
    background-color: #fff;
    -webkit-appearance: none;
  }

  input[type='number']:focus {
    outline: none;
    border: 1px solid #48544c;
    box-shadow: 0 0 0 1px #48544c;
  }

  label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 10px;
    color: #48544c;
    font-size: 14px;
    font-weight: 400;
  }

  input:disabled {
    opacity: 0.4;
  }

  input[type='button']:hover {
    transition: 0.3s all;
  }

  input[type='button'],
  input[type='submit'] {
    -webkit-appearance: none;
  }
}

.numeric-input {
  text-align: center;
}

.date-picker {
  text-align: left;
  width: 100%;
}

.creatable-input {
  font-size: 16px;
  div {
    color: #48544c;
  }
}

input#eventDate, input#eventDateRange {
  width: 100%;
  max-width: 300px;
  margin: 10px 0;
  border-radius: 30px;
  border: 1px solid #a4a7b5;
  box-shadow: 0 0 2px #5b7065;
  padding: 10px 15px;
  text-align: center;
  font-size: 1rem;
}
input#eventDate:focus,
input#eventDateRange:focus,
input#datepicker-1:focus {
  outline: #48544c;
}
.react-datepicker button {
  width: 32px!important;
}
.entry-form .react-datepicker button {
  top: 20px;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  width: 13px;
  height: 13px;
}
.react-datepicker__day:hover,
.react-datepicker__day--in-range,
.react-datepicker__day--selected {
  background-color: #5b7065;
}
.react-datepicker__day--selected:not([aria-disabled=true]):hover, .react-datepicker__day--in-selecting-range:not([aria-disabled=true]):hover, .react-datepicker__day--in-range:not([aria-disabled=true]):hover, .react-datepicker__month-text--selected:not([aria-disabled=true]):hover, .react-datepicker__month-text--in-selecting-range:not([aria-disabled=true]):hover, .react-datepicker__month-text--in-range:not([aria-disabled=true]):hover, .react-datepicker__quarter-text--selected:not([aria-disabled=true]):hover, .react-datepicker__quarter-text--in-selecting-range:not([aria-disabled=true]):hover, .react-datepicker__quarter-text--in-range:not([aria-disabled=true]):hover, .react-datepicker__year-text--selected:not([aria-disabled=true]):hover, .react-datepicker__year-text--in-selecting-range:not([aria-disabled=true]):hover, .react-datepicker__year-text--in-range:not([aria-disabled=true]):hover {
  background-color: #5b7065;
}
.old-entries-container .react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
  margin-bottom: 10px;
}
